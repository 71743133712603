<script setup lang="ts">
useServerSeoMeta({
  title: 'Online leasing af varebiler med ét klik hos Kassebil.dk',
  ogTitle: 'Online leasing af varebiler med ét klik hos Kassebil.dk',
  description: 'Få et nemt og hurtigt overblik over alle mærker og modeller. Design din egen leasingaftale med få klik.',
  ogDescription: 'Danmarks digitale markedsplads for varebiler.',
  ogImage: 'https://res.cloudinary.com/kassebil/image/upload/v1635250183/ogindex.jpg',
})

const { data: promotedModels, error: promotedModelsError } = await useFetch('/api/promoted-factory-models', {
  params: {
    vehicle_type: 'van',
  },
})

if (!promotedModels.value && promotedModelsError.value) {
  throw createError({
    statusCode: promotedModelsError.value.statusCode,
    statusMessage: promotedModelsError.value.statusMessage,
    fatal: true,
  })
}

const { data: promotedProducts, error: promotedProductsError } = await useFetch('/api/non-factory-vehicles/promoted-products', {
  params: {
    vehicle_type: 'van',
  },
})

if (!promotedProducts.value && promotedProductsError.value) {
  throw createError({
    statusCode: promotedProductsError.value.statusCode,
    statusMessage: promotedProductsError.value.statusMessage,
    fatal: true,
  })
}

const uniqueSellingPonts = [{
  title: 'Bestil din bil online',
  description: 'Bestil din bil fra kontoret eller sofaen, du bestemmer selv. Find, skræddersy og bestil din næste varebil online. Nemt.',
  icon: 'DeliveryIcon',
}, {
  title: 'Levering i hele Danmark',
  description: `Når du bestiller en varebil på Kassebil.dk, så sørger vi for, at den leveres nemt, hurtigt og sikkert –
                  uanset hvor i landet, du befinder dig.`,
  icon: 'BoxIcon',
}, {
  title: 'De mest populære mærker',
  description: 'Ford Transit Custom eller Mercedes Vito? Bare rolig. Vi forhandler nemlig alle de mest populære bilmærker.',
  icon: 'VehicleIcon',
}, {
  title: 'Komplet service',
  description: `Når du bestiller hos os, så får du leveret en komplet varebil med alt fra foliering til ekstraudstyr og
                  indbygget reolsystem.`,
  icon: 'ServiceIcon',
}]

const FAQ1 = [
  {
    question: 'Hvem er Kassebil.dk?',
    answer: 'Vi er en online udbyder af varebilsleasing. Hos os kan du både få nye og brugte varebiler, hvor du frit kan konfigurere en leasingaftale så den passer til dit behov.',
  },
  {
    question: 'Hvorfor skal jeg vælge Kassebil.dk?',
    answer: 'Du skal vælge os fordi det er nemt, billigt og fleksibelt. Hos os får du en gennemsigtig løsning, hvor du får fuldt indblik i priser og vilkår på vores platform.',
  },
  {
    question: 'Hvorfor er Kassebil.dk nemmere?',
    answer: 'Hos os er det nemt fordi du online kan vælge den løsning som er rigtig for dig, uanset om du foretrækker finansiel leasing (med restværdi) eller operationel leasing (uden restværdi). Du kan desuden tilvælge eftermontage som firmalogo, reoler, tyverisikring og meget mere, så du får en færdig varebil leveret.',
  },
  {
    question: 'Hvorfor er Kassebil.dk billigere?',
    answer: 'Vi er billigere fordi vi tilbyder leasing på meget attraktive finansieringsvilkår, grundet vores høje volume på antal leasingkontrakter. Alt foregår online, på den måde har vi sparet alle de fysiske omkostninger, som i sidste ende kommer kunderne til gode.',
  },
  {
    question: 'Hvorfor er Kassebil.dk mere fleksibelt?',
    answer: 'Hos os får du en fleksibilitet som ikke er set andre steder. Vi tilbyder et eksklusivt koncept Kassebil Fri som betyder at du til hver en tid kan opsige din leasingaftale med løbende + 1 måned, uanset om du har valgt finansiel eller operationel leasing. På den måde skal du ikke bekymre dig om en uopsigelig binding, hvis din situation skulle ændre sig.',
  },
]
</script>

<template>
  <div>
    <div class="w-full mx-auto max-w-container sm:px-14 px-8">
      <div class="space-y-5">
        <h1>
          <span style="display: inline-block; vertical-align: top; text-decoration: inherit; text-wrap: balance;">
            <span class="text-[26px] leading-[32px] sm:text-3xl md:text-5xl md:leading-[55px] font-regola-bold text-black">
              Online leasing<br class="block">af varebiler med ét klik.
            </span>
          </span>
        </h1>
        <p class="text-base md:text-lg font-regola-medium font-normal max-w-2xl text-gray-600">
          Uanset din erfaring med leasing, gør vi processen nem og gnidningsfri.<br> Vælg din varebil og udfyld formularen, helt uforpligtende.
        </p>
      </div>

      <UButton
        variant="solid"
        size="xl"
        class="font-regola-bold mt-6 rounded-2xl py-4 transition-all hover:scale-[101%] hover:shadow-xl duration-300"
        to="/nye-varebiler"
        trailing-icon="ArrowRightIcon"
      >
        Se alle varebiler
      </UButton>

      <!-- Populære modeller -->
      <div v-if="promotedModels" class="mt-10">
        <div>
          <p class="text-base md:text-lg font-regola-medium font-normal text-black mb-4">
            Populære modeller
          </p>

          <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <FactoryModelCard
              v-for="data in promotedModels"
              :key="data.model.id"
              :model="data.model"
              :from-price="data.lowestMonthlyPrice"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="border-y border-gray-200 sm:py-14 py-8 mt-20">
      <div class="w-full mx-auto max-w-container items-center sm:px-14 px-8">
        <h2 class="text-black text-4xl font-regola-bold mt-2 mb-4">
          Det skal være nemt at lease
        </h2>
        <p class="text-base md:text-lg font-regola-medium text-gray-600">
          Vi er med dig hele vejen: Vi hjælper dig med at finde den bil og det udstyr, der passer til lige præcis dine
          behov, og vi sørger for, at alt fra reolsystemer til foliering er på plads, når din nye følgesvend lander i
          indkørslen.
        </p>
        <div class="w-full max-w-container grid grid-cols-1 md:grid-cols-2 gap-8 mt-12">
          <div class="mt-12 lg:col-span-2 lg:mt-0">
            <dl
              class="space-y-10 sm:grid sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 sm:gap-y-10 sm:space-y-0 lg:gap-x-8"
            >
              <div v-for="{ title, description, icon } in uniqueSellingPonts" :key="title" class="relative">
                <div class="flex">
                  <div class="mr-4 flex-shrink-0">
                    <div class="p-2 inline-flex bg-primary-600 rounded-lg items-center justify-center shrink-0">
                      <Icon :name="icon" class="h-6 w-6 text-white" />
                    </div>
                  </div>
                  <div>
                    <dt class="text-lg font-regola-medium leading-6 text-black">
                      {{ title }}
                    </dt>
                    <dd class="mt-1 text-base text-gray-600">
                      {{ description }}
                    </dd>
                  </div>
                </div>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <div v-if="promotedProducts && promotedProducts.vehicles.length > 0" class="mt-20 w-full mx-auto max-w-container sm:px-14 px-8">
      <div>
        <p class="text-base md:text-lg font-regola-medium font-normal text-black mb-4">
          Seneste brugte varebiler
        </p>

        <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <NonFactoryProductCard
            v-for="car in promotedProducts.vehicles"
            :key="car.id"
            :product="{
              id: car.id,
              name: car.product_name,
              primary_image_url: car.primary_image_url,
              brand_name: car.brand_name,
              model_name: car.model_name,
              mileage: car.mileage,
              year: new Date(car.first_registration_date).getFullYear(),
              gear_type: car.gear_type,
              fuel_type: car.fuel_type,
            }"
            :from-price="car.monthly_price!"
          />
        </div>
      </div>
    </div>

    <!-- Testimonials -->
    <div class="border-y border-gray-200 sm:py-14 py-8 mt-20">
      <div class="w-full mx-auto max-w-container items-center grid grid-cols-1 md:grid-cols-2 gap-8 sm:px-14 px-8">
        <div>
          <h2 class="text-black text-xl font-regola-bold">
            Vi har hjulpet mange virksomheder
          </h2>
          <p class="text-base md:text-lg font-regola-medium font-normal text-gray-600 mt-3 max-w-3xl ">
            Hos Kassebil.dk hjælper vi både lokale håndværkere og store organisationer med at løse deres varebilsbehov –
            uanset om det indbefatter en enkelt varevogn eller en hel flåde.
          </p>
        </div>

        <div class="-my-12">
          <div class="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
            <div class="col-span-1 flex h-28 max-h-28 justify-center rounded-3xl p-8">
              <div class="flex items-center">
                <img
                  class="max-h-8 contrast-0" src="/images/wihlborgs.svg" alt="Juul Bolig ApS logo" loading="lazy"
                >
              </div>
            </div>
            <div class="col-span-1 flex h-28 max-h-28 justify-center rounded-3xl p-8">
              <div class="flex items-center">
                <nuxt-img
                  width="118"
                  height="24"
                  format="webp"
                  class="max-h-6 contrast-0"
                  src="/images/vention.png"
                  alt="Vention ApS logo"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="col-span-1 flex h-28 max-h-28 justify-center rounded-3xl p-8">
              <div class="flex items-center">
                <nuxt-img
                  width="130"
                  height="48" format="webp" class="max-h-12 contrast-0" src="/images/lemus.png" alt="Lemus logo" loading="lazy"
                />
              </div>
            </div>
            <div class="col-span-1 flex h-28 max-h-28 justify-center rounded-3xl p-8">
              <img
                class="max-h-12 contrast-0"
                src="/images/charlottenlund-murerforretning.svg"
                alt="Charlottenlund Murerforretning logo"
                loading="lazy"
              >
            </div>
            <div class="col-span-1 flex h-28 max-h-28 justify-center rounded-3xl p-8">
              <div class="flex items-center">
                <nuxt-img
                  width="199"
                  height="112"
                  format="webp"
                  class="max-h-28 contrast-0"
                  src="/images/boetkjaer-murer.png"
                  alt="Bøtkjær murer logo"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="col-span-1 flex h-28 max-h-28 justify-center rounded-3xl p-6">
              <div class="flex items-center contrast-0">
                <nuxt-img
                  width="107"
                  height="56" format="webp" class="graa max-h-14" src="/images/juulbolig.png" alt="Juul Bolig ApS logo" loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- FAQ -->
    <div class="border-b border-gray-200 sm:py-14 py-8">
      <div class="w-full mx-auto max-w-container items-center sm:px-14 px-8">
        <div class="md:text-center">
          <h2 class="text-black text-2xl md:text-4xl font-regola-bold mt-2 mb-4">
            Spørgsmål og svar
          </h2>
          <p class="text-base md:text-lg font-regola-medium font-normal text-gray-600 mt-3 ">
            Få svar på ofte stillede spørgsmål herunder:
          </p>
        </div>

        <FAQ :questions="FAQ1" />
      </div>
    </div>
  </div>
</template>
